<template>
  <div style="position: relative !important;">
    <b-modal id="modal-danger" ok-variant="danger" ok-title="Confirm" modal-class="modal-danger" centered
      title="deleting source code !" @ok="deleteJson">
      <b-card-text>
        Are you sure you want to delete this JSON Data file ?
      </b-card-text>
    </b-modal>
    <div>
      <b-card class="card-header" no-body>
        <div class="container">
          <div class="row justify-content-between">
            <div class="d-flex align-items-center">
              <h3>
                <feather-icon icon="ListIcon" size="20" />
                List of JSON Data Files Availables
              </h3>
            </div>

            <div class="d-flex align-items-center" style="gap:4px;">
              <div>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" to="/assets/data/create" variant="flat-success"
                  class="btn-icon rounded-circle" v-b-tooltip.hover title="Add New JSON Data">
                  <feather-icon icon="PlusCircleIcon" size="40" />
                </b-button>
              </div>
              <div class="box" v-if="sourcesData.length > 0">
                <form name="search">
                  <input v-model="searchQuery" type="text" class="input-search" name="txt"
                    onmouseout="this.value = ''; this.blur();">
                </form>
                <feather-icon icon="SearchIcon" id="icon-search" />

                <!-- <b-form-input class="col-md-5" v-model="searchQuery" placeholder="Search by name of script" /> -->
              </div>
            </div>
          </div>
        </div>
      </b-card>
      <div v-if="!isLoading">
        <div class="row mt-3 mb-1" v-if="displayedCards.length > 0">
          <div class="col-md-4 col-sm-6" v-for="(jsonFile, index) in displayedCards" :key="index">
            <b-card-group>
              <b-card no-body img-top position="relative">
                <div class="pic-dev">
                  <div class="title">
                    <h3>{{ jsonFile.title }}</h3>
                  </div>
                </div>
                <!-- <b-card-img :src="jsonFile.image_url" img-alt="Image"> -->
                <!-- </b-card-img> -->
                <b-card-title class="m-0 p-0">
                  <div class="d-flex align-items-center justify-content-between mt-1 mx-1">
                    <div>
                      <!-- {{ jsonFile.title }} -->
                    </div>
                    <div>
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-warning"
                        class="btn-icon rounded-circle" v-b-tooltip.hover title="Update" :to="`/assets/data/${jsonFile.id}/update`">
                        <feather-icon icon="EditIcon" size="20" />
                      </b-button>
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger"
                        class="btn-icon rounded-circle" v-b-tooltip.hover title="Delete"
                        @click="popDeleteId(jsonFile.id)">
                        <feather-icon icon="Trash2Icon" size="20" />
                      </b-button>
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-secondary"
                        class="btn-icon rounded-circle" v-b-tooltip.hover title="Preview"
                        @click="preview(jsonFile.json_url)" style="color: #746af8 !important">
                        <feather-icon icon="EyeIcon" size="20" />
                      </b-button>
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-success"
                        class="btn-icon rounded-circle" v-b-tooltip.hover title="Download"
                        style="color: #8dc245 !important" @click="downloadJson(jsonFile.json_url)">
                        <feather-icon icon="DownloadIcon" size="20" />
                      </b-button>
                    </div>
                  </div>
                </b-card-title>
              </b-card>
            </b-card-group>
          </div>
          <div class="col-12" style="position: fixed; bottom: 30px;">
            <div class="col-3">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="fill"></b-pagination>
            </div>
          </div>
        </div>
        <div class="row mt-5 justify-content-center" v-else>
          <div class="col-md-6 col-sm-12">
            <div class="text-center">
              <feather-icon icon="AlertCircleIcon" size="80" />
              <h3 class="my-2" style="font-size: 3rem !important;">No Result Found</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loader-overlay" v-if="isLoading">
      <b-spinner label="Loading..." style="width: 3rem; height: 3rem;" variant="primary" />
    </div>
  </div>
</template>
  
<script>
import {
  BContainer,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BPagination,
  BInputGroup,
  BFormInput,
  BCardGroup,
  BButton,
  BSpinner,
  BModal,
  VBModal,
  BCardTitle,
  BLink,
  BCardText,
  BCardImg,
  BButtonGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BTable,
    BAvatar,
    'b-tooltip': VBTooltip,
    BCard,
    BCardGroup,
    BImg,
    BBadge,
    BRow,
    BLink,
    BCol,
    BSpinner,
    BCardTitle,
    vSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    VBModal,
    BCardText,
    BContainer,
    BCardImg, BButtonGroup
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      sourcesData: [
      ],
      rows: null,
      currentPage: 1,
      perPage: 6,
      isLoading: false,
      searchQuery: '',
      beDeleted: null,

    }
  },
  mounted() {
    this.getJsons('mounted');
  },
  computed: {
    displayedCards() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      let filteredCards = this.sourcesData;
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filteredCards = filteredCards.filter((code) =>
          code.title.toLowerCase().includes(query)
        );
      }
      return filteredCards.slice(start, end);
    },
  },
  methods: {
    preview(url) {
      window.open(url, '_blank');
    },
    downloadJson(url) {
      axios.get(url, {
        responseType: 'blob'
      }).then(({ data }) => {
        const blob = new Blob([data], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.json';
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch((err) => {
        console.error(err);
      })
    },

    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    getIndexUrl(id) {
      axios.post('/assets/code/preview', {
        id
      }).then(({ data }) => {
        window.open(data.index_html_url, '_blank');
      })
    },
    async getJsons(from) {
      this.isLoading = true
      await axios.get('/assets/data').then(({ data }) => {
        if (from !== "mounted") {
          if (data.data.length % 6 === 0) {
            this.currentPage = Math.floor(data.data.length / 6);
          } else {
            this.currentPage = Math.floor(data.data.length / 6) + 1;
          }
        }
        this.sourcesData = data.data;
        this.rows = this.sourcesData.length;
        this.isLoading = false
      })
        .catch((error) => {

          this.isLoading = false
          console.error(error);
        }
        );
    },
    popDeleteId(id) {
      this.beDeleted = id;
      this.$root.$emit("bv::show::modal", "modal-danger");
    },
    async deleteJson() {
      try {
        await axios.delete(`/assets/data/${this.beDeleted}/delete`).then(({ data }) => {
          if (data.status === 'success') {
            this.showToast(
              "success",
              "TrashIcon",
              "Success",
              data.message
            );
          }
        }).then(this.getJsons('deleted'));
      } catch (error) {
        console.error(error);
      }

    }
  },
}
</script>
  
<style scoped>
.box {
  position: relative;
}



.input-search {
  padding: 7px;
  width: 40px;
  height: 40px;
  background: none;
  border: 3px solid #1095F4;
  border-radius: 50px;
  box-sizing: border-box;
  font-size: 26px;
  color: #1095F4;
  outline: none;
  transition: .5s;
}

.box:hover .input-search {
  width: 350px;
  background: inherit;
  border-radius: 10px;
}

.box #icon-search {
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #1095F4;
  transition: .2s;
}

.box:hover #icon-search {
  opacity: 0;
  z-index: -1;
}

.card-header {
  padding: 5px 15px;
  margin: 0;
  display: grid;
}

/* Media query for smaller screens */
@media (max-width: 767px) {
  .download-badge {
    position: static;
    margin-bottom: 10px;
  }
}
.pic-dev {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  border-radius: 3px;
  background: linear-gradient(-60deg, #0071c2, #67baf5);
  
}
.title {
  width: 100%;
  text-align: center;
  background: #272626;
  font: 600;
  padding-block: 10px ;
  opacity: 0.7;
}
.title h3 {
  
  color: white;
}


.loader-overlay {
  z-index: 9999;
  display: flex;
  flex: 1;
  height: 50vh;
  justify-content: center;
  align-items: center;
  /* Add blur effect to the background */
}
</style>
